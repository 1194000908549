import React from "react";
import ExpensesCategoriesComponent from "./ExpensesCategoriesComponent";
import {
  LIST_EXPCAT,
  HOST,
  UPDATE_EXPCAT,
  DELETE_EXPCAT,
} from "../../Network/ApiPaths";
// import getResponse from "../../Network/GetDataResponse";
// import postDataResponse from "../../Network/PostDataResponse";
import Uri from "../../Network/Uri";
import Pagination from "react-bootstrap-4-pagination";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
import axios from "axios";
let clientHost;
let storeFresh;
class ExpensesCategoriesContainer extends React.Component {
  constructor(props) {
    super();
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
    this.state = {
      expensescategories: [],
      reset: false,
      test: false,
      cName: "",
      totalRecords: 0,
      livePage: 1,
      addedexpensescategories: false,
      editedexpensescategories: false,
      selectedexpensescategories: [],
      newName: "",
      deletedexpensescategoriesId: "",
      deletedexpensescategories: false,
    };
  }

  onNameChange = (e) => {
    this.setState({ cName: e.target.value });
  };
  onNameUpdate = (e) => {
    this.setState({ newName: e.target.value });
  };
  onAdd = (e) => {
    e.preventDefault();
    this.setState({
      test: true,
      reset: true,
      cName: this.state.cName,
    });
    // POST API WITH DATA
    const requestBody = {
      name: this.state.cName,
      addon_status: "NO",
      status: "ACTIVE",
      store_id: storeFresh.storeId,
    };
    postClientDataResponse(
      HOST +
      UPDATE_EXPCAT,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        this.getexpensescategories();
        this.setState({
          addedexpensescategories: true,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  getexpensescategories = () => {
    var apiPath = new Uri(HOST + LIST_EXPCAT)
      .addQueryParam("store_id", storeFresh.storeId)
      .addQueryParam("offset", 0)
      .addQueryParam("limit", 10);
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          reset: false,
          expensescategories: onSuccessResponse.result,
          totalRecords: onSuccessResponse.totalRecords,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  initiatedEditing = (p) => {
    this.setState({
      selectedexpensescategories: p,
    });
  };
  editexpensescategories = (c) => {
    if (
      this.state.newName === "" ||
      this.state.newName === this.state.selectedexpensescategories.name
    ) {
      alert("no changes to update");
    } else {
      let cid = this.state.selectedexpensescategories;
      const requestBody = {
        name: this.state.newName,
        addon_status: "ACTIVE",
        store_id: storeFresh.storeId,
        id: cid.id,
      };
      postClientDataResponse(
        HOST +
         UPDATE_EXPCAT,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            editedexpensescategories: true,
          });
          this.getexpensescategories ();
        },
        (onError) => {
          console.log(onError);
        }
      );
    }
  };
  onexpensescategoriesTriggerDelete = (e) => {
    this.setState({ deletedexpensescategoriesId: e });
  };
  deleteexpensescategories = (decid) => {
    this.setState({
      deletedexpensescategories: true,
    });
    var apiPath = new Uri(HOST +  DELETE_EXPCAT).addQueryParam(
      "id",
      this.state.deletedexpensescategoriesId
    );
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        // this.setState({
        //   deletedCat: true,
        // });
        this.getexpensescategories();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  handleChange = (e, id) => {
    let img = e.target.files[0];
    const handleFileRead = async (event) => {
      const file = img;
      const base64 = await this.convertBase64(file);
      const requestBody = {
        id: id,
        image: base64,
      };
      postClientDataResponse(
       HOST + UPDATE_EXPCAT,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.getexpensescategories();
        },
        (onError) => {
          console.log(onError.message);
        }
      );
    };
    handleFileRead();
  };
  render() {
    let offsetVal = 0;
    let pagesCount = Math.round(Number(this.state.totalRecords) / 10);
    const { livePage } = this.state;
    let paginationConfig = {
      totalPages: pagesCount,
      currentPage: livePage,
      // currentPage: 1,
      size: "sm",
      onClick: (page) => {
        // alert(page);
        offsetVal = page * 10 - 10;
        var apiPath = new Uri(HOST + LIST_EXPCAT)
          .addQueryParam("storeid", storeFresh.storeId)
          .addQueryParam("offset", offsetVal)
          .addQueryParam("limit", 10);
        getResponseClient(
          apiPath,
          storeFresh.key,
          (onSuccessResponse) => {
            this.setState({
              expensescategories: onSuccessResponse.result,
              livePage: (offsetVal + 10) / 10,
            });
          },
          (onError) => {
            console.log(onError.message);
          }
        );
      },
    };
    return (
      <>
        <ExpensesCategoriesComponent
          data={this.state.expensescategories}
          nameChange={this.onNameChange}
          handleChange={this.handleChange}
          // handleClick={this.handleClick}
          onNameUpdate={this.onNameUpdate}
          idChange={this.onIdChange}
          add={this.onAdd}
          selectedexpensescategories={this.state.selectedexpensescategories}
          addedexpensescategories={this.state.addedexpensescategories}
          update={this.editexpensescategories}
          initiatedEditing={this.initiatedEditing}
          editedexpensescategories={this.state.editedexpensescategories}
          deleteexpensescategories={this.deleteexpensescategories}
          deletedexpensescategories={this.state.deletedexpensescategories}
          onexpensescategoriesTriggerDelete={this.onexpensescategoriesTriggerDelete}
          reset={this.state.reset}
        />
        <Pagination {...paginationConfig} />
        {/* <Pagination
        totalPages={Math.round(this.state.totalRecords/10,1)}
        currentPage={1}
        showMax={Math.round(this.state.totalRecords/10,1)}
        activeBgColor="#d4515c"
        activeBorderColor="#d4515c"
        color="#d4515c"
      /> */}
      </>
    );
  }
  async componentDidMount() {
    this.getexpensescategories();
  }
}
export default ExpensesCategoriesContainer;
