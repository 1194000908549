import React from "react";
import Footer from "../Components/Footer";
// import SuperAdminReports from "../Components/SuperAdminReports";
// import BussinessUsers from "../Components/BussinessUsers";
// import SuperAdminStores from "../Components/SuperAdminStores";
import Stores2 from "../Components/Stores2";
import Employees from "../Components/Employees";
import SubCategories from "../Components/SubCategories";
import SuperAdminRoot from "../Components/SuperAdminRoot";
import Categories from "../Components/Categories";
import ExpensesCategories from "./ExpensesCategories";
import ExpensesSubCategories from "../Components/ExpensesSubCategories";
import Expenses from "../Components/Expenses";
import Products from "../Components/Products";
import Reports from "../Components/Reports/Reports";
import ItemReports from "../Components/ItemReports";
import CategoryReports from "../Components/CategoryReports";
import BillReports from "../Components/BillReports";
import ExecutiveReports from "../Components/ExecutiveReports";
import TodaysReport from "../Components/TodaysReport";
import Orders from "../Components/Orders/Orders";
import Deals from "../Components/Deals/Deals";
import Offers from "../Components/Offers/Offers";
// import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../Network/GetClientDataResponse";
// import getResponse from "../../Network/GetDataResponse";
import Uri from "../Network/Uri";
import {
  HOST,
  
  KEY,
  // STORE_LIST,
  // BRANDID,
  // AD_HOST,
  // AD_KEY,
} from "../Network/ApiPaths";
// import Pincodes from "../Components/Pincodes";
// const userCheck = window.localStorage.getItem("access");
class SuperAdminDash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      superAdminHome: true,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      storeId: "",
      stores: [],
      // mallId: BRANDID,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    };
  }
  showSuperAdminHome = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: true,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSuperAdminStores = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: true,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSuperAdminReports = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: true,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSuperAdminItemReports = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: true,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };

  showSuperAdminCategoryReports = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: true,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSuperAdminBillReports = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: true,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSuperAdminExecReports = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: true,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSuperAdminHourlyReports = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: true,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };

  showAppBussinessUsers = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: true,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showCategories = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: true,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showSubCategories = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: true,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showExpensesCategories = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      expensescategories: true,
      expensesSubCategories: false,
      expenses: false,
      categories: false,
      subCategories: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showExpensesSubCategories = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensesSubCategories: true,
      expensescategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showExpenses = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      expensescategories: false,
      categories: false,
      subCategories: false,
      expensesSubCategories: false,
      expenses: true,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showProducts = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: true,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showOrders = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: true,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showEmployees = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: true,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showDeals = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: true,
      offers: false,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showOffers = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: true,
      pincodes: false,
      ordersByLocation: false,
    });
  };
  showPinCodes = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: true,
      ordersByLocation: false,
    });
  };
  showOrdersByLocation = (e) => {
    e.preventDefault();
    this.setState({
      superAdminHome: false,
      superAdminStores: false,
      superAdminReports: false,
      itemReports: false,
      categoryReports: false,
      billReports: false,
      execReports: false,
      hourlyReports: false,
      bUsers: false,
      categories: false,
      subCategories: false,
      expensescategories: false,
      expensesSubCategories: false,
      expenses: false,
      products: false,
      orders: false,
      employees: false,
      deals: false,
      offers: false,
      pincodes: false,
      ordersByLocation: true,
    });
  };
  storeSelect = (s) => {
    // let d = s.target.getAttribute("data-value");
    // let storeId = s.id;
    let storeId = s.store.id;
    // var selected = s.mallStore;
    var selected = this.state.stores.find((d) => d.store.id === storeId);
    // Update state with object this.setState({selected});
    const clientAccess = {
      host: selected.host,
      key: selected.x_api_key,
      storeId: selected.store.id,
      // storeCode: selected.store_code,
      storeName: selected.store.name,
      latitute: selected.store.latitute,
      longitude: selected.store.longitude,
    };
    localStorage.setItem("clientAccess", JSON.stringify(clientAccess));
    this.props.nameOfStore(selected.store.name);
    this.setState({
      storeId: s.store.id,
    });

    const storeCheck = JSON.parse(window.localStorage.getItem("access"));
    let access = {
      name: storeCheck.name,
      mail: storeCheck.mail,
      phone: storeCheck.phone,
      role: storeCheck.role,
      userId: storeCheck.userId,
      storeId: s.store.id,
      // imgOne: storeCheck.kyc_one_image,
      // imgTwo: storeCheck.kyc_two_image,
      accImage: storeCheck.accImage,
      // // // storeName: storeCheck.storeName,
      // // // longitude: storeCheck.longitude,
      // // // latitute: storeCheck.latitute,
      address: s.store.address,
      gst: s.store.gst_number,
      // city: storeCheck.city,
    };
    localStorage.setItem("access", JSON.stringify(access));
  };
  render() {
    const userCheck = window.localStorage.getItem("access");
    // const storeCheck = JSON.stringify(
    //   window.localStorage.getItem("clientAccess")
    // );
    return (
      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          <div className="sidebar-header d-flex align-items-center">
            <div className="avatar">
              <img
                src="img/brand_logo.jpg"
                alt="..."
                className="img-fluid rounded-circle"
              />
            </div>
            <div className="title">
              <h1 className="h4">{JSON.parse(userCheck).name}</h1>
              <p>{JSON.parse(userCheck).role}</p>
              {/* <span>{JSON.parse(userCheck).role}</span> */}
            </div>
          </div>
          <ul className="list-unstyled">
            <li
              onClick={this.showSuperAdminHome}
              className={this.state.superAdminHome ? "active" : null}
            >
              <a href="profile">
                <i className="fa fa-home"></i>Home
              </a>
            </li>

            {/* <ul className="list-unstyled">
              <li>
                <a
                  href="#exampledropdownDropdown5"
                  aria-expanded="false"
                  data-toggle="collapse"
                >
                  <i className="icon-interface-windows"></i>Select Store
                </a>
                <ul
                  id="exampledropdownDropdown5"
                  className="collapse list-unstyled "
                >
                  {this.state.stores.map((p) => (
                    <li
                      onClick={() => this.storeSelect(p)}
                      className={this.state.reports ? "active" : null}
                    >
                      <a href="#/">
                        <i className="icon-grid"></i>
                        {p.storeName}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul> */}
            {this.state.storeId !== "" ? (
              <>
                <li
                  onClick={this.showEmployees}
                  className={this.state.employees ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-user-circle-o"></i>Employees
                  </a>
                </li>
                <li
                  onClick={this.showCategories}
                  className={this.state.categories ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Categories
                  </a>
                </li>
                <li
                  onClick={this.showSubCategories}
                  className={this.state.subCategories ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-grid"></i>Sub Categories
                  </a>
                </li>
                <li
                  onClick={this.showExpensesCategories}
                  className={this.state.expensescategories ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Expenses Categories
                  </a>
                </li>
                <li
                  onClick={this.showExpensesSubCategories}
                  className={this.state.expensesSubCategories ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>ExpensesSubCategories
                  </a>
                </li>
                <li
                  onClick={this.showExpenses}
                  className={this.state.expenses ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Expenses
                  </a>
                </li>
                <li
                  onClick={this.showProducts}
                  className={this.state.products ? "active" : null}
                >
                  <a href="profile">
                    <i className="fa fa-product-hunt"></i>Products
                  </a>
                </li>
                <li>
                  <a
                    href="#exampledropdownDropdown5"
                    aria-expanded="false"
                    data-toggle="collapse"
                  >
                    <i className="fa fa-file-text"></i>Reports
                  </a>
                  <ul
                    id="exampledropdownDropdown5"
                    className="collapse list-unstyled "
                  >
                    <li
                      onClick={this.showSuperAdminReports}
                      className={this.state.superAdminReports ? "active" : null}
                    >
                      <a href="profile">
                        <i className="fa fa-bar-chart"></i>Analytics
                      </a>
                    </li>
                    <li
                      onClick={this.showSuperAdminItemReports}
                      className={this.state.itemReports ? "active" : null}
                    >
                      <a href="#/">
                        <i className="icon-padnote"></i>Item Wise Reports
                      </a>
                    </li>
                    <li
                      onClick={this.showSuperAdminCategoryReports}
                      className={this.state.categoryReports ? "active" : null}
                    >
                      <a href="#/">
                        <i className="fa fa-list-alt"></i>Category Reports
                      </a>
                    </li>
                    <li
                      onClick={this.showSuperAdminBillReports}
                      className={this.state.billReports ? "active" : null}
                    >
                      <a href="#/">
                        <i className="icon-padnote"></i>Bill Wise Reports
                      </a>
                    </li>
                    <li
                      onClick={this.showSuperAdminExecReports}
                      className={this.state.execReports ? "active" : null}
                    >
                      <a href="#/">
                        <i className="icon-padnote"></i>Executive Reports
                      </a>
                    </li>
                    <li
                      onClick={this.showSuperAdminHourlyReports}
                      className={this.state.hourlyReports ? "active" : null}
                    >
                      <a href="#/">
                        <i className="icon-padnote"></i>Hourly Reports
                      </a>
                    </li>
                  </ul>
                </li>
                <li
                  onClick={this.showOrders}
                  className={this.state.orders ? "active" : null}
                >
                  <a href="profile">
                    <i className="fa fa-shopping-bag"></i>Orders
                  </a>
                </li>
                {/* <li
                  onClick={this.showOrdersByLocation}
                  className={this.state.ordersByLocation ? "active" : null}
                >
                  <a href="profile">
                    <i className="fa fa-shopping-bag"></i>Orders By Location
                  </a>
                </li> */}
                <li
                  onClick={this.showDeals}
                  className={this.state.deals ? "active" : null}
                >
                  <a href="profile">
                    <i className="fa fa-gift"></i>Deals
                  </a>
                </li>
                <li
                  onClick={this.showOffers}
                  className={this.state.offers ? "active" : null}
                >
                  <a href="profile">
                    <i className="icon-grid"></i>Offers
                  </a>
                </li>
                {/* <li
                  onClick={this.showPinCodes}
                  className={this.state.pincodes ? "active" : null}
                >
                  <a href="profile">
                    <i className="icon-grid"></i>Pincodes
                  </a>
                </li> */}
              </>
            ) : null}
            <li
              onClick={this.showSuperAdminStores}
              className={this.state.superAdminStores ? "active" : null}
            >
              <a href="profile">
                <i className="fa fa-university"></i>Stores
              </a>
            </li>
            {/* <li
              onClick={this.showAppBussinessUsers}
              className={this.state.bUsers ? "active" : null}
            >
              <a href="profile">
                <i className="icon-grid"></i>B-Users
              </a>
            </li> */}
          </ul>
          <span className="heading"></span>
        </nav>
        <div className="content-inner">
          {this.state.superAdminHome ? (
            <SuperAdminRoot
              selectedStoreId={this.storeSelect}
              storeId={this.state.storeId}
              stores={this.state.stores !== [] ? this.state.stores : null}
            />
          ) : null}
          {this.state.employees ? <Employees /> : null}
          {this.state.superAdminStores ? <Stores2 /> : null}
          {/* {this.state.superAdminReports ? <SuperAdminReports /> : null} */}
          {/* {this.state.superAdminStores ? <SuperAdminStores /> : null} */}
          {/* {this.state.bUsers ? <BussinessUsers /> : null} */}
          {this.state.superAdminReports ? <Reports /> : null}
          {this.state.itemReports ? <ItemReports /> : null}
          {this.state.categoryReports ? <CategoryReports /> : null}
          {this.state.billReports ? <BillReports /> : null}
          {this.state.execReports ? <ExecutiveReports /> : null}
          {this.state.hourlyReports ? <TodaysReport /> : null}
          {this.state.categories ? <Categories /> : null}
          {this.state.expensescategories ? <ExpensesCategories /> : null}
          {this.state.expensesSubCategories ? <ExpensesSubCategories /> : null}
          {this.state.subCategories ? <SubCategories /> : null}
          {this.state.expenses ? <Expenses/> : null}
          {this.state.products ? <Products /> : null}
          {this.state.orders ? <Orders storeId={this.state.storeId} /> : null}
          {this.state.deals ? <Deals /> : null}
          {this.state.offers ? <Offers /> : null}
          {/* {this.state.pincodes ? <Pincodes /> : null} */}
          <Footer />
        </div>
      </div>
    );
  }
  async componentDidMount() {
    const clientAccess = {
      host:HOST,
      key: KEY,
    };
    localStorage.setItem("clientAccess", JSON.stringify(clientAccess));
    var apiPath = new Uri(HOST + `Store/listAllStoresReports`);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          stores: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }
}
export default SuperAdminDash;
