export const KEY = "TSITGF2022";
export const HOST = "http://godavarifoods.treosoftit.com/backend/api/";
// export const HOST = "http://.treosoftit.com/backend/api/";
//
export const HOST_PREFIX = HOST.split("api")[0];
export const IMG_PATH_CATEGORY = "images/category";
export const IMG_PATH_DEALS = "images/deals";
export const IMG_PATH_PRODUCT = HOST_PREFIX + "/images/menu/";
export const CURRENCY = "fa fa-inr";
//GET
export const MENU_LIST = "Menu/listmenu";
export const DEALS_LIST = "Deals/listAllDeals";
export const DEALS_DETAILS_LIST = "Menu/getInfo";
export const ACTIVE_DEALS_LIST = "Deals/activeDeals";
export const DEAL_DETAILS = "Deals/dealDetails";
export const OFFERS_LIST = "Offer/listAllOffer";
export const SUBS_LIST = "subscription/listOfSubscription";
export const EMPLOYEES_LIST = "User/employeeByStore";
export const B_USER = "Business/listBusinessUsers";
export const B_USER_DETAILS = "Business/getBusinessDetails";
export const ACTIVATE_B_USER = "User/update";
export const APPROVE_B_USER = "Business/updateBusinessDetails";
export const EMPLOYEE_LIST = "User/employeeByStore";
export const DELIVERYBOYS_LIST = "/User/dbByStore?store_id=13&offset=0";
export const CATEGORY_LIST = "Category/listCategories";
export const USER_LIST = `${HOST}User/usersList`;
export const PINCODE_LIST = "Pincode/listPincodes";
export const CUSTOMERS_LIST = "Customer/listCustomers";
export const ADD_CUSTOMERS = "Customer/updateCustomer";
export const SUBCATEGORY_LIST = "Category/listSubCategories";
//POST
export const ADD_CATEGORY = "Category/updateCategory";
export const DELETE_CATEGORY = "Category/deleteCategory";
export const ADD_SUBCATEGORY = "Category/updateSubCategory";
export const DELETE_SUBCATEGORY = "Category/deleteSubCategory";
export const ADD_PRODUCT = "Menu/updateItem";
export const ADD_PRICE = "Menu/updatePrice";
export const ADD_PINCODE = "Category/updateCategory";
export const ADD_STORE = "Store/addStore";
export const UPDATE_STORE = "Store/updateStore";
export const CHECK_USER = "User/login";
export const ADD_USER_DETAILS = "User/addUser";
export const ADD_TO_CART = "Cart/updateCart";
export const REMOVE_FROM_CART = "Cart/deleteCartItem";
export const DELETE_CART = "Cart/deleteAllCart";
export const PLACE_ORDER = "Order/placeOrder";
export const UPDATE_ORDER = "Order/updateOrder";
export const NEW_DEAL = "Deals/updateDeal";
export const NEW_DEAL_DETAIL = "Deals/updateDealDetails";
export const NEW_OFFER = "Offer/updateOffer";
export const UPDATE_CATEGORY_IMAGE = "Category/uploadImage";
export const UPDATE_PRODUCT_IMAGE = "Menu/uploadImage";

//(REPORTS)
export const MONTHLY_REPORT = "Reports/getMonthlyReport";
export const HOURLY_REPORT = "Reports/getHourlySales";
export const TOP10_ITEMS = "Reports/getTopTenItems";
export const USERS = "Reports/getUserCount?role=user";
export const MISC = "Report/periodicSaleReport";
export const STORE_LIST = "Store/listStoreWithReport";
export const TOTAL_USER_COUNT = "Report/getUserCount";
export const UPDATE_USER_DETAILS = "User/update";
export const CATEGORY_REPORT = "Reports/getProductWithCatSale";

//expensescategory
export const ADD_EXPCAT = "Expenses/addExpensesCategory"
export const LIST_EXPCAT = "Expenses/listExpensesCategory"
export const UPDATE_EXPCAT = "Expenses/updateExpensesCategory"
export const DELETE_EXPCAT = "Expenses/deleteExpensesCategory"

//expensessubcategory
export const LIST_EXPSUBCAT = "Expenses/listSubCategory"
export const UPDATE_EXPSUBCAT = "Expenses/updateSubCategory"
export const DELETE_EXPSUBCAT = "Expenses/deleteSubCategory"
export const UPLOAD_IMAGE = "Expenses/uploadImage"

//expenses
export const LIST_EXP = "Expenses/listexpenses"
export const UPDATE_EXP = "Expenses/updateItem"
export const DELETE_EXP = "Expenses/deleteexpenses"
