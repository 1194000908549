import React from "react";
import { HOST_PREFIX } from "../../Network/ApiPaths";
class ExpensesCategoriesComponent extends React.Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <section className="client no-padding-top">
        <div className="container-fluid">
          <div className="row">
            <div className="card-body text-center">
              <div
                id="myModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Add Expenses category
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form
                        ref={(el) =>
                          this.props.reset === false
                            ? (this.myFormRef = el)
                            : this.myFormRef.reset()
                        }
                      >
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            onChange={this.props.nameChange}
                            type="email"
                            placeholder="Expensecategory name"
                            className="form-control"
                          />
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <div>
                              <input
                                id="option"
                                type="checkbox"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="option"> Status</label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-group"
                          onClick={this.props.add} data-dismiss={
                            this.props.addedCat === true ? "" : "modal"

                          }
                        ><input
                            type="submit"
                            value="ADD"
                            className="btn btn-primary"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal2"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Edit Expenses Category
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <form>
                        <div className="form-group">
                          <label>Name</label>
                          <input
                            onChange={this.props.onNameUpdate}
                            type="email"
                            placeholder="Expensescategory name"
                            className="form-control"
                          />

                          <span>&nbsp;{this.props.selectedexpensescategories.name}</span>
                        </div>
                        <div className="form-group">
                          <label>Image</label>
                          {this.props.selectedexpensescategories.image ? (
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={
                                HOST_PREFIX +
                                "/images/category/" +
                                this.props.selectedexpensescategories.id +
                                "/" +
                                this.props.selectedexpensescategories.image
                              }
                            >
                               {this.props.selectedexpensescategories.image}
                            </a>
                          ) : (
                            <>
                              {/* <input type="file" id="myFile" name="html" style="width: 90px;" onchange="this.style.width = '100%';" /> */}
                              {/* <input type="file" style="width: 90px;" onchange="this.style.width'100%';" /> */}
                              <div>
                                <input
                                  type="file"
                                  style={{ width: " 110px" }}
                                  onChange={(e) =>
                                    this.props.handleChange(
                                      e,
                                      this.props.selectedexpensescategories.id
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}
                          {this.props.selectedexpensescategories.image ? (
                            <div>
                              <input
                                type="file"
                                style={{ width: " 110px" }}
                                onChange={(e) =>
                                  this.props.handleChange(
                                    e,
                                    this.props.selectedexpensescategories.id
                                  )
                                }
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group row">
                          <div className="col-sm-9">
                            <div>
                              <input
                                id="option"
                                type="checkbox"
                                value=""
                                defaultChecked
                              />
                              <label htmlFor="option"> Status</label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-group"
                          onClick={this.props.update}
                          data-dismiss={
                            this.props.editedexpensesCat === true ? "" : "modal"
                          }
                        >
                          <input
                            type="submit"
                            value="UPDATE"
                            className="btn btn-primary"
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body text-center">
              <div
                id="myModal3"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                className="modal fade text-left"
              >
                <div role="document" className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 id="exampleModalLabel" className="modal-title">
                        Do you Want to Delete This Expenses Category
                      </h4>
                      <button
                        type="button"
                        data-dismiss="modal"
                        aria-label="Close"
                        className="close"
                      >
                        <span aria-hidden="true">x</span>
                      </button>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-primary"
                        ata-dismiss={
                          this.props.CatDeleted === true ? "modal" : null
                        }
                        onClick={this.props.deleteexpensescategories}
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        data-dismiss="modal"
                        className="btn btn-secondary"
                      >
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-lg-12">
              <div className="card">
                <div className="card-close">
                  <div className="dropdown">
                    <button
                      type="button"
                      data-toggle="modal"
                      data-target="#myModal"
                      className="btn btn-primary"
                    >
                      Add ExpensesCategory
                    </button>
                    <span>
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    {/* <button
                  type="button"
                  id="closeCard1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard1"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div> */}
                  </div>
                </div>
                <div className="card-header d-flex align-items-center">
                  <h3 className="h4">Expenses</h3>
                  <br />
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Name</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.data
                          ? this.props.data !== [] ||
                            this.props.data !== null ||
                            this.props.data !== undefined
                            ? this.props.data.map((p, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{p.name}</td>
                                  <td>{p.status}</td>
                                  {/* <td>{p.code}</td> */}
                                  <td
                                  onClick={() =>
                                    this.props.initiatedEditing(p)
                                  }
                                  data-toggle="modal"
                                  data-target="#myModal2"
                                  className="fa fa-edit fa-lg"
                                >
                                  
                                  </td>
                                <td
                                  onClick={() =>
                                    this.props.onexpensescategoriesTriggerDelete(p.id)
                                  }
                                  data-toggle="modal"
                                  data-target="#myModal3"
                                  className="fa fa-trash-o fa-lg"
                                >

                                </td>
                                </tr>
                                
                              ))
                            : this.props.data
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ExpensesCategoriesComponent;
